import React from 'react';
import Logo from './Components/Logo.js'
import Nav from './Components/Nav.js'
import Title from './Components/Title.js'
import Footer from './Components/Footer.js'

import './Projects.css';

const Projects = () => {
    const projects = [
        {
            title: 'Single-Family Home Construction',
            description: 'A project to build a single-family home in a suburban neighborhood, focusing on modern design and energy efficiency.',
        },
        {
            title: 'Eco-Friendly Community',
            description: 'A planned community that prioritizes green living and sustainability.',
        },
        {
            title: 'Duplex Development',
            description: 'Construction of a duplex to provide affordable housing options while maximizing the use of land.',
        },
    ];

    return (
        <div className="projects">
            <Logo />
            <Nav />
            <Title text='Projects' />
            <div className="projects_list">
                {
                    projects.map((project, index) => (
                        <div className="projects_listcard" key={index}>
                            <div className="projects_listcardtitle">{project.title}</div>
                            <div className="projects_listcarddescription">{project.description}</div>
                        </div>
                    ))
                }
            </div>
            <Footer />
        </div>
    );
};

export default Projects;
