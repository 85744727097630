import React from 'react';
import Logo from './Components/Logo.js'
import Nav from './Components/Nav.js'
import Title from './Components/Title.js'
import Footer from './Components/Footer.js'

import './Services.css';

const Services = () => {
    const services = [
        {
            title: 'General Contracting',
            description: 'End-to-end management of construction projects, including labor, materials, equipment, and coordination of all subcontractors.',
        },
        {
            title: 'Project Management',
            description: 'Supervising the entire construction process to ensure it is completed on time, within budget, and according to specifications.',
        },
        {
            title: 'Design-Build Services',
            description: 'A streamlined service where the contractor is responsible for both designing and building the project for efficiency and speed.',
        },
        {
            title: 'Construction Management',
            description: 'Overseeing construction on behalf of the owner, ensuring all phases are properly executed, and projects meet standards.',
        },
        {
            title: 'Pre-Construction Services',
            description: 'Feasibility studies, budgeting, site analysis, cost estimation, and planning before construction begins.',
        },
        {
            title: 'Renovation & Remodeling',
            description: 'Upgrading or restoring existing structures to modernize their appearance, functionality, or structural integrity.',
        },
        {
            title: 'New Construction',
            description: 'Ground-up construction services, including site preparation, foundation work, and full construction of buildings.',
        },
        {
            title: 'Demolition Services',
            description: 'Safe dismantling and removal of structures to clear the site for new construction.',
        },
        {
            title: 'Consultation Services',
            description: 'Expert advice on construction plans, building codes, zoning regulations, and best practices.',
        },
        {
            title: 'Green Building & Sustainability',
            description: 'Eco-friendly construction practices, including energy-efficient designs and sustainable materials.',
        },
        {
            title: 'Civil Engineering Services',
            description: 'Large-scale infrastructure projects, including roads, bridges, dams, and water systems.',
        },
        {
            title: 'Mechanical, Electrical, and Plumbing (MEP)',
            description: 'Installation and maintenance of essential systems such as HVAC, electrical wiring, and plumbing.',
        },
        {
            title: 'Structural Engineering',
            description: 'Designing and constructing building frameworks that withstand various loads and environmental conditions.',
        },
        {
            title: 'Steel and Concrete Construction',
            description: 'Working with structural materials like steel beams and concrete foundations for durability and strength.',
        },
        {
            title: 'Tenant Improvement (TI)',
            description: 'Customizing commercial spaces like offices or retail stores to fit tenant needs.',
        },
        {
            title: 'Site Development',
            description: 'Preparing construction sites, including grading, excavation, drainage systems, and utility installation.',
        },
        {
            title: 'Interior Finishing',
            description: 'Completing final touches on a building’s interior, including painting, flooring, and cabinetry.',
        },
        {
            title: 'Waterproofing & Insulation',
            description: 'Protecting buildings from water damage and improving energy efficiency.',
        },
        {
            title: 'Roofing Services',
            description: 'Installing, maintaining, and repairing roofs for residential, commercial, or industrial buildings.',
        },
        {
            title: 'Concrete and Masonry Work',
            description: 'Laying concrete foundations, brick, stone, or block walls, and other masonry structures.',
        },
        {
            title: 'Carpentry & Woodworking',
            description: 'Providing skilled carpentry for wooden structures, doors, windows, trim, and custom cabinetry.',
        },
        {
            title: 'Custom Home Building',
            description: 'Constructing high-end, custom-designed homes tailored to individual client preferences.',
        },
        {
            title: 'Facility Maintenance',
            description: 'Ongoing maintenance services for commercial or industrial buildings, including HVAC, plumbing, and electrical.',
        },
        {
            title: 'Landscaping and Hardscaping',
            description: 'Designing and building outdoor spaces such as gardens, patios, walkways, and driveways.',
        },
        {
            title: 'Security Systems Installation',
            description: 'Installing and integrating security systems, including surveillance cameras, alarms, and access control.',
        },
    ];

    return (
        <div className="services">
            <Logo />
            <Nav />
            <Title text='Services' />
            <div className="services_list">
                {
                    services.map((service, index) => (
                        <div className="services_listcard" key={index}>
                            <div className="services_listcardtitle">{service.title}</div>
                            <div className="services_listcarddescription">{service.description}</div>
                        </div>
                    ))
                }
            </div>
            <Footer />
        </div>
    );
};

export default Services;