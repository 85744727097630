import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Nav.css';

const Nav = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <nav className="nav">
            <button className="nav_button" onClick={() => handleNavigation('/')}>Home</button>
            <button className="nav_button" onClick={() => handleNavigation('/projects')}>Projects</button>
            <button className="nav_button" onClick={() => handleNavigation('/services')}>Services</button>
            <button className="nav_button" onClick={() => handleNavigation('/careers')}>Careers</button>
            <button className="nav_button" onClick={() => handleNavigation('/contact')}>Contact Us</button>
        </nav>
    );
};

export default Nav;