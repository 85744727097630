import React from 'react';
import './Gallery.css';

const Gallery = ({ pictures }) => {
    return (
        <div className="gallery-grid">
            {pictures.map((picture, index) => (
                <div className="gallery-item" key={index}>
                    <img src={picture} alt={`gallery-img-${index}`} />
                </div>
            ))}
        </div>
    );
};

export default Gallery;