import React from 'react';
import { useNavigate } from 'react-router-dom';

import facebookIcon from '../Assets/Footer/Facebook.png';
import instagramIcon from '../Assets/Footer/Instagram.png';
import linkedinIcon from '../Assets/Footer/Linkedin.png';

import './Footer.css';

function Footer() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <footer className="footer">
            <div className="footer_container">
                <div className="footer_column">
                    <h4>Get in Touch with Contracting Dynamics</h4>
                    <p>We’re ready to hear your project needs, no matter how big or small. Reach out to us today and let us know how we can help you turn your ideas into reality.</p>
                </div>

                <div className="footer_column">
                    <h4>Quick Links</h4>
                    <div className="footer_links">
                        <div className="footer_link" onClick={() => handleNavigation('/')}>Home</div>
                        <div className="footer_link" onClick={() => handleNavigation('/projects')}>Projects</div>
                        <div className="footer_link" onClick={() => handleNavigation('/services')}>Services</div>
                        <div className="footer_link" onClick={() => handleNavigation('/careers')}>Careers</div>
                        <div className="footer_link" onClick={() => handleNavigation('/contact')}>Contact Us</div>
                    </div>
                </div>

                <div className="footer_column">
                    <h4>Contact Info</h4>
                    <p>info@contractingdynamics.com</p>
                    <p>+971 58 9713818</p>
                    <p>GCC AND MENA</p>
                </div>

                <div className="footer_column">
                    <h4>Follow Us</h4>
                    <div className="footer-social-links">
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                            <img src={facebookIcon} alt="Facebook" className="social-icon" />
                        </a>
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                            <img src={instagramIcon} alt="Instagram" className="social-icon" />
                        </a>
                        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                            <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
                        </a>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <p>© 2024 Contracting Dynamics. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;