import React, { useState } from 'react';
import Logo from './Components/Logo.js'
import Nav from './Components/Nav.js'
import Title from './Components/Title.js'
import Footer from './Components/Footer.js'

import './Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
        setFormData({
            name: '',
            email: '',
            message: '',
        });
    };

    return (
        <div className="contact">
            <Logo />
            <Nav />
            <Title text='Contact Us' />
            <form className='contract_form' onSubmit={handleSubmit}>
                <div className="contract_formgroup">
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="contract_formgroup">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="contract_formgroup">
                    <label htmlFor="message">Message:</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <button type="submit" className="contract_formsubmit">Send Message</button>
            </form>
            <Footer />
        </div>
    );
};

export default Contact;
