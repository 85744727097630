import React from 'react';
import Nav from './Components/Nav.js';
import Title from './Components/Title.js';
import Footer from './Components/Footer.js';
import Logo from './Components/Logo.js';
import './Careers.css';

const getRandomDate = () => {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const randomDate = new Date(
        oneMonthAgo.getTime() + Math.random() * (today.getTime() - oneMonthAgo.getTime())
    );

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return randomDate.toLocaleDateString(undefined, options);
};

const Careers = () => {
    const jobListings = [
        {
            date: getRandomDate(),
            location: 'Dubai, UAE',
            title: 'Construction Project Manager',
        },
        {
            date: getRandomDate(),
            location: 'Riyadh, Saudi Arabia',
            title: 'Site Supervisor',
        },
        {
            date: getRandomDate(),
            location: 'Doha, Qatar',
            title: 'Estimator',
        },
        {
            date: getRandomDate(),
            location: 'Kuwait City, Kuwait',
            title: 'Quality Control Inspector',
        },
        {
            date: getRandomDate(),
            location: 'Manama, Bahrain',
            title: 'Construction Safety Officer',
        },
        {
            date: getRandomDate(),
            location: 'Muscat, Oman',
            title: 'Civil Engineer',
        },
        {
            date: getRandomDate(),
            location: 'Abu Dhabi, UAE',
            title: 'Architectural Designer',
        },
        {
            date: getRandomDate(),
            location: 'Jeddah, Saudi Arabia',
            title: 'Mechanical Estimator',
        },
        {
            date: getRandomDate(),
            location: 'Al Khobar, Saudi Arabia',
            title: 'General Contractor',
        },
        {
            date: getRandomDate(),
            location: 'Sharjah, UAE',
            title: 'Construction Consultant',
        },
    ];

    return (
        <div className="careers">
            <Logo />
            <Nav />
            <Title text='Careers' />
            <p className='careers_description'>We are always looking for talented individuals who are passionate about their work and are eager to make a difference. Explore the current job openings and find your perfect fit:</p>
            <table className="careers_joblistings">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Location</th>
                        <th>Title</th>
                        <th>Apply</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        jobListings.map((job, index) => (
                            <tr key={index}>
                                <td>{job.date}</td>
                                <td>{job.location}</td>
                                <td>{job.title}</td>
                                <td>
                                    <button className="apply-button">Apply</button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <p className='careers_note'>Please ensure you are eligible for the job before sending your CV.</p>
            <Footer />
        </div>
    );
};

export default Careers;