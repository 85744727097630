import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './Home.js';
import Projects from './Projects.js';
import Services from './Services.js';
import Careers from './Careers.js';
import Contact from './Contact.js';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/services" element={<Services />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;