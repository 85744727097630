import { useNavigate } from 'react-router-dom';
import logo from '../Assets/Logo.png';

import './Logo.css';

function Logo() {
    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate('/');
    };

    return (
        <div className='logo'>
            <img src={logo} className="logo_image" alt="logo" onClick={handleNavigation} />
        </div>
    );
}

export default Logo;