import Logo from './Components/Logo.js'
import Nav from './Components/Nav.js'
import Section from './Components/Section.js'
import Gallery from './Components/Gallery.js'
import Footer from './Components/Footer.js'

import { Helmet } from 'react-helmet';

import Image1 from './Assets/Home/Image1.jpg';
import Image2 from './Assets/Home/Image2.jpg';
import Image3 from './Assets/Home/Image3.jpg';
import Image4 from './Assets/Home/Image4.jpg';
import Image5 from './Assets/Home/Image5.jpg';
import Image6 from './Assets/Home/Image6.jpg';

import './Home.css';

function Home() {
    const picturesSection1 = [Image1, Image2, Image3];
    const picturesSection2 = [Image4, Image5, Image6];

    return (
        <div className="home">
            <Helmet>
                <title>Contracting Dynamics | Contracting Company</title>
                <meta name="description" content="Contracting Dynamics is a contracting company in the Middle East and MENA region. We provide top-notch construction, infrastructure, and custom services to meet all your project needs." />

                <meta name="keywords" content="contracting, construction, infrastructure, Middle East, MENA, projects, company" />
                <meta name="author" content="Contracting Dynamics" />

                <meta property="og:title" content="Contracting Dynamics | Contracting Company" />
                <meta property="og:description" content="Providing comprehensive contracting services across the Middle East and MENA region." />
                <meta property="og:image" content="https://example.com/path-to-your-image.jpg" />
                <meta property="og:url" content="https://contractingdynamics.soukakarat.com" />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Contracting Dynamics | Contracting Company" />
                <meta name="twitter:description" content="Offering tailored contracting services for various projects." />
                <meta name="twitter:image" content="https://example.com/path-to-your-image.jpg" />

                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>

            <Logo />
            <Nav />
            <Section title="Welcome to Contracting Dynamics">
                <div className="description">
                    At Contracting Dynamics, we pride ourselves on saying “yes” to all your contracting needs.
                    We bring flexibility, expertise, and a commitment to excellence, ensuring that no project is too big, too small, or too complex for us to handle.
                </div>
            </Section>

            <Gallery pictures={picturesSection1} />

            <Section title="Your Vision, Our Mission">
                <div className="description">
                    Whether it's construction, infrastructure, maintenance, or custom services, we’re ready to meet your requirements and exceed your expectations.
                    From initial concept to completion, Contracting Dynamics is your trusted partner in making your vision a reality.
                </div>
            </Section>

            <Section title="About Us">
                <div className="description">
                    Contracting Dynamics is a versatile contracting firm that has been serving clients across the Middle East and MENA region for years.
                    We specialize in delivering comprehensive solutions tailored to the unique needs of each client.
                    Whether you’re in need of large-scale commercial construction, residential projects, or specialized services, we never say no.
                </div>
            </Section>

            <Gallery pictures={picturesSection2} />

            <Section title="Why Choose Us?">
                <div class="reason">
                    <h3>Unmatched Flexibility</h3>
                    <p>At Contracting Dynamics, we say "yes" to every request. We pride ourselves on being flexible and open to any challenge, no matter how complex or unique.</p>
                </div>

                <div class="reason">
                    <h3>Regional Expertise</h3>
                    <p>Operating in the Middle East and MENA region, we have a deep understanding of the cultural, regulatory, and logistical needs of the area. This enables us to execute projects smoothly, no matter the location.</p>
                </div>

                <div class="reason">
                    <h3>Client Satisfaction</h3>
                    <p>Our mission is to meet and exceed the expectations of every client. We tailor our services to align with your specific goals and ensure that we deliver top-notch results on time and within budget.</p>
                </div>

                <div class="reason">
                    <h3>Comprehensive Solutions</h3>
                    <p>From large-scale infrastructure projects to detailed custom builds, we offer a full range of contracting services. No project is out of our scope.</p>
                </div>
            </Section>
            <Footer />
        </div>
    );
}

export default Home;